$(function() {

    // floating labels
    var floatingClass = 'input-filled';
    $('.form-group-floating input, .form-group-floating textarea').each(function () {
        var parent = $(this).closest('.form-group-floating');
        if(this.value != '') {
            parent.addClass(floatingClass);
        }
    });

    $('.form-group-floating input, .form-group-floating textarea').on('change', function () {
        var parent = $(this).closest('.form-group-floating');
        if(this.value != '') {
            parent.addClass(floatingClass);
        } else {
            parent.removeClass(floatingClass);
        }
    });

    // form validation
    if($('html').attr('lang') == 'de') {
        Parsley.setLocale('de');
    }else {
        Parsley.setLocale('en');
    }

    if($('.validate-form').length > 0) {
        $('.validate-form').each(function () {
           $(this).parsley({
               focus: 'none',
               trigger: 'change',
               errorClass: 'is-invalid',
               successClass: 'is-valid',
               classHandler: function (el) {
                   if(el.$element.attr('type') == 'radio' || el.$element.attr('type') == 'checkbox') {
                       return el.$element.parents('.form-group').find('.custom-control-input');
                   }
               },
               errorsMessagesDisabled: true,
           }).on('form:validate', function () {
               /* Prevent E-Mail duplicates */
               /* Set class disabled on click - no attribute disable because tx_formframework doesn't like that ... */
               if(!$(this.$element).hasClass('wizard-form')) {
                   var $submit = $(this.$element).find("button[type='submit']");
               }else {
                   var $submit = $(this.$element).parents('.wizard-modal').find("button.wizard-btn-submit");
               }
               $submit
                   .text($submit.attr('data-disabled'))
                   .addClass('disabled');

           }).on('form:error', function () {
               /* Prevent E-Mail duplicates */
               /* Enable submit Button again if there is an validation error */
               if(!$(this.$element).hasClass('wizard-form')) {
                   var $submit = $(this.$element).find("button[type='submit']");
               }else {
                   var $submit = $(this.$element).parents('.wizard-modal').find("button.wizard-btn-submit");
               }
               $submit
                   .text($submit.attr('data-text'))
                   .removeClass('disabled');

               /* scroll to first error */
               if(!$(this.$element).hasClass('wizard-form')) {
                   var targetOffset = $('.is-invalid').first().parents('.form-group').offset().top;
                   var navbar = $('.navbar.fixed-top');
                   if(navbar.length && targetOffset !== 0){
                       targetOffset -= navbar.outerHeight();
                   }
                   $('html,body').animate({scrollTop: targetOffset}, 500);
               }
           });
        });
    }

    if($('form[id*=eventregistrationform]').length > 0) {

        /* Event Registration Form show prefilled values in Template (by GET Parameters) */
        var $eventForm = $('form[id*=eventregistrationform]').first(),
            identifier = '#' + $eventForm.attr('id'),
            eventTitle = $(identifier + '-hidden-title').val(),
            eventDate = $(identifier +  '-hidden-date').val(),
            eventHeadline = $('html').attr('lang') == 'de' ? 'Anmeldung' : 'Registration';

        var eventTemplate = '<div class="event-info mb-6">' +
            '<header class="frame-header">' +
            '<span class="text-style-primary font-lg d-block">' + eventHeadline + '</span>' +
            '<h2 class="element-header text-left mb-2"> ' + eventTitle + ' </h2></header>' +
            '<p>' + eventDate + '</p>' +
            '</div>';

        if(eventTitle.length > 0 || eventDate.length > 0) {
            $eventForm.before(eventTemplate);
        }
    }

});
