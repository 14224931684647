$(function() {
    /*console.log('hello wizard :)');*/
    var focusalbeQueryString = 'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])';
    var wizardItem = {
        $wizard: null,
        $tabs: null,
        totalNumSteps: 1,
        currentTab: 1,
        $progressTotal: null,
        $progressCurrrent: null,
        $form: null,
        $backButton: null,
        $nextButton: null,
        $submitButton: null,
        $closeButton: null,
        triggeredByKeyboard: false,
        init: function(elem) {
            var self = this;

            self.$wizard = $(elem);
            self.$tabs = self.$wizard.find('.wizard-tab');
            self.totalNumSteps = self.$tabs.length;
            self.$progressCurrent = $('.wizard-progress-current');

            self.$progressTotal = $('.wizard-progress-total');
            self.$progressTotal.text(self.totalNumSteps);

            self.$form = self.$wizard.find('form.wizard-form');
            self.$backButton = self.$wizard.find('.wizard-btn-back');
            self.$nextButton = self.$wizard.find('.wizard-btn-next');
            self.$submitButton = self.$wizard.find('.wizard-btn-submit');
            //hide back and submit button
            self.$backButton.hide();
            self.$submitButton.hide();

            self.$closeButton = self.$wizard.find('button.close');

            // Prepare sections by setting the `data-parsley-group` attribute to 'block-1', 'block-2', etc.
            self.$tabs.each(function(index, elem) {
                $(elem).find(':input').attr('data-parsley-group', 'block-' + (index+1));

                $(elem).find(focusalbeQueryString).first().addClass('first-focusable');
                $(elem).find(focusalbeQueryString).last().addClass('last-focusable');
            });

            self.initEventListeners();

        },
        initEventListeners: function() {
            var self = this;

            self.$backButton.on('click', function () {
                self.backOrNext(-1);
            });

            self.$nextButton.on('click', function () {
                // Exit the function if any field in the current tab is invalid:
                self.$form.parsley().whenValidate({
                    group: 'block-' + self.currentTab
                }).done(function() {
                    self.backOrNext(1);
                    // Remove disabled state from submit button.. added on validation
                    self.$submitButton.text(self.$submitButton.attr('data-text')).removeClass('disabled');
                });
            });

            self.$submitButton.on('click', function () {
                //form is not submitted correctly fom outside trigger.. so we use a hidden submit button
                self.$form.find("button[type='submit']").trigger('click');
            });

            // hadle keydown and focus on wizad buttons/inputs (focus trap)
            self.$closeButton.on('keydown', self.handleKeydownOnClose);
            self.$nextButton.on('keydown', self.handleKeydownOnNext);
            self.$backButton.on('keydown', self.handleKeydownOnBack);

            $('.wizard-tab').on('keydown', self.handleKeydownOnInput);
        },
        updateProgress: function(current) {
            var self = this;
            self.$progressCurrent.text(current);
        },
        backOrNext: function(n) {
            var self = this;

            //don't allow back on first tab
            if(self.currentTab == 1 && n == -1){
                return false;
            }

            var lastTab = self.currentTab;
            self.currentTab = self.currentTab + n;
            /*console.log('Navigate from Tab ' + lastTab + ' to Tab ' + self.currentTab);*/

            // hide current Tab
            self.hideTab(lastTab);

            // show correct tab
            self.showTab(self.currentTab);

            /* Functionality for description per Tab.. not needed in the moment
            // if Description for tab is available show it.. ohterwise show default
            var desc = self.$wizard.find('.wizard-tab-description[data-step="'+self.currentTab+'"]');
            if(desc.length > 0) {
                self.hideDescription(lastTab);
                self.showDescription(self.currentTab);
            }else {
                if(!self.$wizard.find('.wizard-tab-description[data-step="0"]').hasClass('active')) {
                    self.hideDescription(lastTab);
                    self.showDescription(0);
                }
            }
             */
        },
        hideTab: function(index) {
            var self = this;
            self.$wizard.find('.wizard-tab[data-step="'+index+'"]').removeClass('show active');

        },
        showTab: function(index) {
            var self = this;

            self.$wizard.find('.wizard-tab[data-step="'+index+'"]').addClass('show active');
            self.updateProgress(index);

            //set focus if triggered by Keyboard
            if(self.triggeredByKeyboard) {
                var $activeTab = self.$wizard.find('.wizard-tab.active');
                var $firstFocusable = $activeTab.find(focusalbeQueryString).first();
                if($firstFocusable.attr('type') === 'radio') {
                    $firstFocusable = self.getFocusableRadio($firstFocusable);
                }
                $firstFocusable.focus();

                self.triggeredByKeyboard = false;
            }

            // hide back button on first step
            if(index == 1) {
                self.$backButton.addClass('d-none').hide();
            }else {
                if(self.$backButton.hasClass('d-none')) {
                    self.$backButton.removeClass('d-none').show();
                }
            }

            // hide next button / show submit button on last step
            if(index == self.totalNumSteps) {
                self.$nextButton.addClass('d-none').hide();
                self.$submitButton.removeClass('d-none').show()
            }else {
                if(self.$nextButton.hasClass('d-none')) {
                    self.$submitButton.addClass('d-none').hide();
                    self.$nextButton.removeClass('d-none').show()
                }
            }

        },
        hideDescription: function(index) {
            var self = this;
            var elem = self.$wizard.find('.wizard-tab-description[data-step="'+index+'"]');
            if (elem.length == 0) {
                elem = self.$wizard.find('.wizard-tab-description[data-step="0"]');
            }

            elem.removeClass('show active');

        },
        showDescription: function(index) {
            var self = this;

            self.$wizard.find('.wizard-tab-description[data-step="'+index+'"]').addClass('show active');
        },
        handleKeydownOnClose: function (e) {
            /*var self = this; not working here because this is the button */
            var isTabPressed = e.key === 'Tab' || e.keyCode === 9;
            if (!isTabPressed) {
                return;
            }

            var $activeTab = wizardItem.$wizard.find('.wizard-tab.active');
            var $firstFocusable = $activeTab.find(focusalbeQueryString).first();
            if($firstFocusable.attr('type') === 'radio') {
                $firstFocusable = wizardItem.getFocusableRadio($firstFocusable);
            }
            $firstFocusable.focus();
            e.preventDefault();
        },
        handleKeydownOnBack: function (e) {
            /*var self = this; not working here because this is the button */

            if((e.key === 'Enter' || e.keyCode === 13) || (e.keyCode === 32)){
                //Enter or Space is pressed == submit -> focus to next Element
                wizardItem.triggeredByKeyboard = true;
            }

            var isTabPressed = e.key === 'Tab' || e.keyCode === 9;
            if (!isTabPressed) {
                return;
            }

            if(e.shiftKey) {
                //shift key pressed (shift+tab) == go back
                var $activeTab = wizardItem.$wizard.find('.wizard-tab.active');
                var $lastFocusable = $activeTab.find(focusalbeQueryString).last();
                if($lastFocusable.attr('type') === 'radio') {
                    $lastFocusable = wizardItem.getFocusableRadio($lastFocusable);
                }
                $lastFocusable.focus();

                e.preventDefault();
            }else {
                if(!wizardItem.$submitButton.hasClass('d-none')) {
                    wizardItem.$submitButton.focus();

                    e.preventDefault();
                }
            }

        },
        handleKeydownOnNext: function (e) {
            /*var self = this; not working here because this is the button */
            if((e.key === 'Enter' || e.keyCode === 13) || (e.keyCode === 32)){
                //Enter or Space is pressed == submit -> focus to next Element
                wizardItem.triggeredByKeyboard = true;
            }

            var isTabPressed = e.key === 'Tab' || e.keyCode === 9;
            if (!isTabPressed) {
                return;
            }

            if(e.shiftKey) {
                //shift key pressed (shift+tab) == go back
                if(wizardItem.$backButton.hasClass('d-none')) {
                    var $activeTab = wizardItem.$wizard.find('.wizard-tab.active');
                    var $lastFocusable = $activeTab.find(focusalbeQueryString).last();
                    if($lastFocusable.attr('type') === 'radio') {
                        $lastFocusable = wizardItem.getFocusableRadio($lastFocusable);
                    }
                    $lastFocusable.focus();
                }else{
                    wizardItem.$backButton.focus();
                }
            }else {
                wizardItem.$closeButton.focus();
            }
            e.preventDefault();

        },
        handleKeydownOnInput: function (e) {
            /*var self = this; not working here because this is the button */
            var isTabPressed = e.key === 'Tab' || e.keyCode === 9;
            if (!isTabPressed) {
                return;
            }
            var handleFirst = false;
            var handleLast = false;

            if($(e.target).attr('type') === 'radio') {
                if(e.shiftKey) {
                    handleFirst = true;
                }else {
                    handleLast = true;
                }
            }else {
                if(e.shiftKey) {
                    if($(e.target).hasClass('first-focusable')) {
                        handleFirst = true;
                    }
                }else {
                    if($(e.target).hasClass('last-focusable')) {
                        handleLast = true;
                    }
                }
            }

            if(handleFirst) {
                //shift key pressed (shift+tab) == go back
                wizardItem.$closeButton.focus();
                e.preventDefault();
            }

            if(handleLast) {
                //focus next step button or back if visible
                if(wizardItem.$backButton.hasClass('d-none')) {
                    wizardItem.$nextButton.focus();
                }else{
                    wizardItem.$backButton.focus();
                }
                e.preventDefault();
            }
        },
        handleKeydownOnFirst: function (e) {
            /*var self = this; not working here because this is the button */
            var isTabPressed = e.key === 'Tab' || e.keyCode === 9;
            if (!isTabPressed) {
                return;
            }
            if(e.shiftKey) {
                //shift key pressed (shift+tab) == go back
                wizardItem.$closeButton.focus();
                e.preventDefault();
            }
        },
        handleKeydownOnLast: function (e) {
            /*var self = this; not working here because this is the button */
            var isTabPressed = e.key === 'Tab' || e.keyCode === 9;
            if (!isTabPressed) {
                return;
            }
            if(!e.shiftKey) {
                //focus next step button or back if visible
                if(wizardItem.$backButton.hasClass('d-none')) {
                    wizardItem.$nextButton.focus();
                }else{
                    wizardItem.$backButton.focus();
                }
                e.preventDefault();
            }
        },
        getFocusableRadio: function ($elem) {
            var $radioElement = $('input[name="' + $elem.attr('name') + '"]:checked');
            if($radioElement.length) {
                //if there is a selected radio in this group return it
                return $radioElement;
            }
            //otherwise return first one
            return $('input[name="' + $elem.attr('name') + '"]').first();
        }
    };


    // Handle Modal-Link Clicks without data-toggle attribute -> set in link wizard
    $('a[href*="#modal"]:not([data-toggle="modal"])').on('click', function() {

        var $modal = $(this.hash);
        if($modal.length > 0 && $modal.hasClass('modal')) {
            $modal.modal('show');
        }

        return false;
    });

    var wizard = $('.wizard-modal').first();
    wizardItem.init(wizard);

    //Open wizard if #wizard is in URL on page load
    if(location.hash == '#wizard') {
        $('.wizard-btn').first().trigger('click');
    }

});



